import React from 'react';
import { useAddRFPMutation } from '../Utility/RFP_API'
import { useNavigate } from "react-router-dom";

export default function NewRFP(props) {
  const [company, setCompany] = React.useState('');
  const [name, setName] = React.useState('');
  const [initiative, setInitiative] = React.useState('');
  const setTabKey = props.setTabKey;
  const [addNewRFP] = useAddRFPMutation()
  const navigate = useNavigate();

  const addRFP = (body) => (event) => {
    event.preventDefault();
    addNewRFP({  body });
    navigate("/rfp_list");
  }

  return (
    <>
 
      <form onSubmit={addRFP(
        {
          company: company,
          name: name,
          initiative: initiative
        })}>
        <div className="form-group row">
          <label className="col-sm-1 col-form-label">Company</label>
          <div className="col-sm-6">
            <input type="text" className="form-control" value={company} onChange={(e) => setCompany(e.target.value)} required/>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-1 col-form-label">Name</label>
          <div className="col-sm-6">
            <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} required />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-1 col-form-label">Initiative</label>
          <div className="col-sm-6">
            <textarea className="form-control" rows="3" value={initiative} onChange={(e) => setInitiative(e.target.value)}></textarea>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-sm-10">
            <button type="submit" className="btn btn-primary"> Add </button>
          </div>
        </div>
      </form>
    </>
  );
}
