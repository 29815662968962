import React, { useRef, useEffect, useState } from "react";
import { Card } from "react-bootstrap";

const MAX_POSSIBLE_HEIGHT = 750;

const ExpandableText = ({ maxHeight, responseType, className, displayText, children }) => {
  const ref = useRef();
  const [shouldShowExpand, setShouldShowExpand] = useState(false);
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    if (ref.current.scrollHeight > maxHeight) {
      setShouldShowExpand(true);
      setExpanded(false);
    }
  }, [maxHeight,responseType,displayText]);

  return (
    <Card.Text as="div" ref={ref}>
      <div
        style={{ maxHeight: expanded ? MAX_POSSIBLE_HEIGHT : maxHeight }}
        className={className}
      >
        {displayText}
        
      </div>
      {shouldShowExpand && (
        <button className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" onClick={() => setExpanded(!expanded)}>View More</button>
      )}
    </Card.Text>
  );
};

export default function ResponseTextBlock(props) {
  return <ExpandableText displayText={props.text} className={props.className} responseType={props.responseType} maxHeight={500}></ExpandableText>;
}
