import React, { useState, useEffect, useMemo } from 'react';
import {
  DataSheetGrid,
  textColumn,
  keyColumn,
  intColumn
} from 'react-datasheet-grid'
import uuid from 'react-uuid'
import { useBulkQuestionMutation }  from '../Utility/RFP_API'
import { Spinner, Modal} from 'react-bootstrap';


// Import the style only once in your app!
import 'react-datasheet-grid/dist/style.css'

export function ListQuestionsBulk(props) {
  const rfp_id = props.rfp_id;
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setData(props.questions);
    setPrevData(props.questions);
  }, [props.questions]); 

  const createdRowIds = useMemo(() => new Set(), [])
  const deletedRowIds = useMemo(() => new Set(), [])
  const updatedRowIds = useMemo(() => new Set(), [])


  const [ data, setData ] = useState(props.questions)
  const [prevData, setPrevData] = useState(props.questions)

  const [bulkQuestionsTrigger, { isLoading }] = useBulkQuestionMutation();


  const columns = [
    { ...keyColumn('sequence', intColumn), title: 'Sequence' },
    { ...keyColumn('category', textColumn), title: 'Category' },
    { ...keyColumn('question', textColumn), title: 'Question' },
    { ...keyColumn('answer', textColumn), title: 'Answer' },
    { ...keyColumn('notes', textColumn), title: 'Notes' },
  ]

  const genId = () => {
    return uuid();
  }


  const cancel = () => {
    setData(prevData)
    createdRowIds.clear()
    deletedRowIds.clear()
    updatedRowIds.clear()
  }

  const commit = () => {
    var validationPassed = true;
    setErrorMessage("");

    /* Perform insert, update, and delete to the database here */
  
    const newData = data.filter(({ id }) => !deletedRowIds.has(id))
    setData(newData)
    setPrevData(newData)

    /*Perform some validation */
    const missingQuestionFromUpdates = data.filter(({ id }) => updatedRowIds.has(id)).filter((question) => question.question == null && (question.answer !=null || question.category != null ));
    if(missingQuestionFromUpdates.length > 0){
      console.log("Detected an updated row is missing required field 'Question' " + JSON.stringify(missingQuestionFromUpdates));
      setErrorMessage("Detected an updated row is missing required field 'Question' " + JSON.stringify(missingQuestionFromUpdates));

      validationPassed = false;
    }

    if(validationPassed){
      const newlyCreatedData = data.filter(({ id }) => createdRowIds.has(id)).filter((question) => question.question != null)
      const deletedData = data.filter(({ id }) => deletedRowIds.has(id))
      const updatedData = data.filter(({ id }) => updatedRowIds.has(id)).filter((question) => !(question.question == null && question.answer ==null && question.category == null && question.notes==null ))
      const updatedDataThatShouldBeDelete = data.filter(({ id }) => updatedRowIds.has(id)).filter((question) => question.question == null && question.answer ==null && question.category == null )

      const bulk_update_payload = {
        create: newlyCreatedData,
        update: updatedData,
        delete: deletedData.concat(updatedDataThatShouldBeDelete)
      }

      console.log("newlycreateddata=" + JSON.stringify(newlyCreatedData))
      console.log("deletedData=" + JSON.stringify(deletedData))
      console.log("updatedData=" + JSON.stringify(updatedData))
      console.log("updatedDataThatShouldBeDelete=" + JSON.stringify(updatedDataThatShouldBeDelete))

      if(newlyCreatedData.length > 0 || updatedData.length > 0 || deletedData.length > 0 || updatedDataThatShouldBeDelete.length > 0){
        bulkQuestionsTrigger({ rfp_id: rfp_id, body: bulk_update_payload })
        .unwrap()
        .then((payload) => console.log('fulfilled', payload))
        .catch((error) => console.error('rejected', error))  
      }

      createdRowIds.clear()
      deletedRowIds.clear()
      updatedRowIds.clear()
    }
  }


  return (
    <div>
    
     {(errorMessage === "")
      ? <></>
      : <><div className="py-2 alert alert-danger">{errorMessage}</div></>
     }
    

     <div className="py-3">
     <div>
      Help / Tips:
      <ul>
        <li>Click the "+Add" Button to add more rows</li>
        <li>Your modifications are not saved automatically.  You must click "Commit" button to save your changes</li>
        <li>To undo unsaved changes, click the "Cancel" button</li>
        
      </ul>
      
     </div>
     <DataSheetGrid
      value={data}
      columns={columns}
      height={800}
      stickyRightColumn={{
        component: ({ deleteRow }) => (
          <button className="btn button-light"  onClick={deleteRow}><span>
          <i className="fa fa-trash" ></i>
        </span></button>
        ),
      }}

      createRow={() => ({ id: genId() })}
      duplicateRow={({ rowData }) => ({ ...rowData, id: genId() })}
      rowClassName={({ rowData }) => {
        if (deletedRowIds.has(rowData.id)) {
          return 'row-deleted'
        }
        if (createdRowIds.has(rowData.id)) {
          return 'row-created'
        }
        if (updatedRowIds.has(rowData.id)) {
          return 'row-updated'
        }
      }}
      onChange={(newValue, operations) => {

        for (const operation of operations) {
          if (operation.type === 'CREATE') {
            newValue
              .slice(operation.fromRowIndex, operation.toRowIndex)
              .forEach(({ id }) => createdRowIds.add(id))
          }

          if (operation.type === 'UPDATE') {
            newValue
              .slice(operation.fromRowIndex, operation.toRowIndex)
              .forEach(({ id }) => {
                if (!createdRowIds.has(id) && !deletedRowIds.has(id)) {
                  updatedRowIds.add(id)
                }
              })
          }

          if (operation.type === 'DELETE') {
            let keptRows = 0

            data
              .slice(operation.fromRowIndex, operation.toRowIndex)
              .forEach(({ id }, i) => {
                updatedRowIds.delete(id)

                if (createdRowIds.has(id)) {
                  createdRowIds.delete(id)
                } else {
                  deletedRowIds.add(id)
                  newValue.splice(
                    operation.fromRowIndex + keptRows++,
                    0,
                    data[operation.fromRowIndex + i]
                  )
                }
              })
          }
        }

        setData(newValue)
      }}

      />
  </div>

  <div className="py-3">
    <div className="btn-group" role="group" aria-label="Basic example">
      <button type="button" className="btn btn-primary mr-2" onClick={cancel}>Cancel</button>
      <button type="button" className="btn btn-primary" onClick={commit}>Commit</button>
    </div>
  </div>

  <MyVerticallyCenteredModal show={isLoading}/>

    </div>  
  );
}



function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >

      <Modal.Body>
        <div className="p-5 text-center">
          Loading <Spinner
                as="span"
                animation="grow"
                size="xl"
                role="status"
                aria-hidden="true"
              />
        </div>
        <div></div>
      </Modal.Body>
    </Modal>
  );
}