import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import { Type } from 'react-bootstrap-table2-editor';
import { Link } from 'react-router-dom';

export function ListRFPs(props) {
  const rfpsCopy = JSON.parse(JSON.stringify(props.questions));

  const columns = [
    {
      dataField: 'company',
      text: 'Company',
      sort: true,
      headerClasses: 'category-column',
      headerStyle: (colum, colIndex) => {
        return { width: '20%',  };
      },
    },
    {
      dataField: 'name',
      text: 'Name',
      editor: { type: Type.TEXTAREA },
      sort: true,
      headerClasses: 'question-column',
      headerStyle: (colum, colIndex) => {
        return { width: '80%' };
      },
      formatter: (cellContent, row) => {
        return (
          <>
            <span><Link to={row.id}>{row.name}</Link></span>
          </>
        );
      }
    }
  ];


  return (
    <>
      {/* <div className="row">
        <div className='col-8'>
      <div className=".container-md" id="list-rfp">
    
      </div>
      </div>
      </div> */}
      <div className="card shadow mb-4">
                                <div className="card-header py-3">
                                    <h6 className="m-0 font-weight-bold text-primary">RFP List</h6>
                                </div>
                                <div className="card-body">
                                <BootstrapTable
          keyField="id"
          data={rfpsCopy}
          columns={columns}
          bootstrap4
          striped
          hover
          condensed
          tabIndexCell
        />
                                </div>
                            </div>
      </>
  );
}
