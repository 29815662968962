import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getFusionResponse, getStatus } from "../Utility/SearchSlice";
import ClipboardCopy from "../Utility/ClipboardCopy";
import ResponseTextBlock from "./ResponseTextBlock";
import Thumbs from "../Utility/Thumbs";
import SetAnswer from "../Utility/SetAnswer";
import Bookmark from "../Utility/Bookmark";
import NoResults from "../Results/NoResults";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import { RectShape } from "react-placeholder/lib/placeholders";
import PageControls from "./PageControls";


function ResponseResults(props) {
  const fusionResponse = useSelector(getFusionResponse);

  const docs =
    fusionResponse && fusionResponse.response && fusionResponse.response.docs
      ? fusionResponse.response.docs
      : [];

  const numFound =
    fusionResponse && fusionResponse.response && fusionResponse.response.docs
      ? fusionResponse.response.numFound
      : -1;

  var qStatus = useSelector(getStatus);

  const start =
    fusionResponse && fusionResponse.responseHeader
      ? fusionResponse.responseHeader.params.start
      : 0;

  const starting = parseInt(start) + 1;

  const facetCounts =
    fusionResponse && fusionResponse.facet_counts
      ? fusionResponse.facet_counts
      : {};

  const rows =
    fusionResponse && fusionResponse.responseHeader
      ? fusionResponse.responseHeader.params.rows
      : "24";

  const showing =
    parseInt(rows) < parseInt(numFound)
      ? parseInt(rows) + parseInt(start)
      : parseInt(numFound);

  const displayNumFound =
    fusionResponse && fusionResponse.response && fusionResponse.response.docs
      ? fusionResponse.response.numFound
      : 0;

  const [initialLlmAnswer, setInitialLlmAnswer] = useState(null);
  const [keywords, setKeywords] = useState([]);

  useEffect(() => {
    
    if (fusionResponse && fusionResponse.fusion && fusionResponse.fusion.llm_answer) {
      setInitialLlmAnswer(fusionResponse.fusion.llm_answer[0]);
    }
    if (fusionResponse && fusionResponse.responseHeader && fusionResponse.responseHeader.params['llm_key_raw_response']) {
      const keyWordStr = fusionResponse.responseHeader.params['llm_key_raw_response'];
      const keyWordsArray = keyWordStr.split(', ');
      setKeywords(keyWordsArray);
    }
  }, [fusionResponse, initialLlmAnswer]);

  function numberWithCommas(x) {
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
    return x;
  }

  const resultsPlaceHolder = (
    <div className="container-fluid">
      <div className="row mb-1">
        <div className="col-sm float-left align-self-center">
          Searching RFPs....
        </div>
        <div className="col-sm">
          <RectShape color="#FFF" style={{ height: 54 }} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg">
          <div className="card mb-2">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <RectShape color="#E0E0E0" style={{ height: 100 }} />
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-2">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <RectShape color="#E0E0E0" style={{ height: 100 }} />
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-2">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <RectShape color="#E0E0E0" style={{ height: 100 }} />
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-2">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <RectShape color="#E0E0E0" style={{ height: 100 }} />
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-2">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <RectShape color="#E0E0E0" style={{ height: 100 }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  if (numFound > 0) {
    return (
      <div className="container-fluid">
        <div className="row mb-1">
          <div className="col">
            <div className="card summary-card mb-3">
              <div className="card-header">LLM Generated Answer</div>
              <div className="card-body">
                <h5 className="card-title">Answer</h5>
                <p className="card-text text-gray-900">{initialLlmAnswer}</p>
                <div>
                  <p className="small-title">Extracted Keywords:</p>
                  {keywords.map((item, index) => (
                    <span key={index} className="badge badge-light badge-custom">
                      {item}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-1">
          <div className="col-sm float-left align-self-center">
            {displayNumFound > 0
              ? "Showing " +
                starting +
                "-" +
                showing +
                " of " +
                numberWithCommas(displayNumFound) +
                " results."
              : "0 Results"}
          </div>
          <div className="col-sm">
            <PageControls numFound={numFound} />
          </div>
        </div>
        <div className="row">
          <div className="col-lg">
            {docs.map((doc, i) => (
              <div className="card mb-2" key={doc.id + "-" + i}>
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <ResponseTextBlock
                        text={doc.question_t}
                        responseType="question"
                        className="result-title"
                      />
                    </div>
                    <div className="col-2 text-right">
                      <span className="result-controls border border-light rounded bg-gray-200">
                        <Thumbs type="thumbs-up" doc={doc} />
                        <Bookmark doc={doc} />
                        <ClipboardCopy doc={doc} />
                        {props.setAnswer != null ? (
                          <SetAnswer
                            answer={doc.answer_t}
                            setAnswer={props.setAnswer}
                          />
                        ) : (
                          <></>
                        )}
                      </span>
                    </div>
                  </div>
                  <ResponseTextBlock
                    text={doc.answer_t}
                    responseType="answer"
                    className="text-gray-900"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-sm d-flex justify-content-center">
            <PageControls numFound={numFound} />
          </div>
        </div>
      </div>
    );
  } else if (numFound === -1) {
    if (qStatus.pending === true) {
      return (
        <ReactPlaceholder
          showLoadingAnimation="true"
          customPlaceholder={resultsPlaceHolder}
        ></ReactPlaceholder>
      );
    }
  } else {
    return <NoResults />;
  }
}

export default ResponseResults;