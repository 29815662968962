import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setRequestParam,
  searchFusion,
  clearAllFilters,
  clearAllParams,
  clearResponse,
} from "../Utility/SearchSlice.js";
import SwitchOption from "../Utility/SwitchOption.js";

function QuestionTextBox(props) {
  
  /*
    Ensures that we clear previous search results, and query parameters if we're reloading/coming back to this search from somewhere else.
    Ensures it clears previous results if we're reloading/coming back from this search from somewhere else.
  */
  useEffect(() => {
    dispatch(clearAllFilters());
    dispatch(clearAllParams());
    dispatch(clearResponse());

    if (props.query !== null && props.query !== "") {
      const payload = {
        paramName: "q",
        paramValue: props.query,
      };
      dispatch(setRequestParam(payload));

      const startPayload = {
        paramName: "start",
        paramValue: "0",
      };
      dispatch(setRequestParam(startPayload));


      const userpayload = {
        paramName: "user_id",
        paramValue: props.emailId,
      };
      dispatch(setRequestParam(userpayload));
      dispatch(searchFusion());
    }
  }, [props.query]);

  const dispatch = useDispatch();
  const initialSearch = props.query != null ? props.query : "";
  const [searchBarText, setSearchBarText] = useState(initialSearch);
  const [showOptions, setShowOptions] = useState(false);

  //event for capturing the user hitting enter while typing in the search text field
  const handleOnKeyDown = (event) => {
    if (event.key === "Enter") {
      if (event.target.value !== null && event.target.value.trim() !== "") {
        const payload = {
          paramName: "q",
          paramValue: event.target.value.trim(),
        };
        dispatch(setRequestParam(payload));
  
        const startPayload = {
          paramName: "start",
          paramValue: "0",
        };
        dispatch(setRequestParam(startPayload));

        const userpayload = {
          paramName: "user_id",
          paramValue: props.emailId,
        };
        dispatch(setRequestParam(userpayload));

        dispatch(searchFusion());
        setSearchBarText(searchBarText.trim());
      }
    }
  };

  function refreshPage() {
    window.location.reload(false);
  }

  //event for capturing the user clicking on the search button
  const handleButtonPress = (event) => {
    event.preventDefault();
    if (searchBarText !== null && searchBarText.trim() !== "") {
      const payload = {
        paramName: "q",
        paramValue: searchBarText.trim(),
      };
      dispatch(setRequestParam(payload));
      const userpayload = {
        paramName: "user_id",
        paramValue: props.emailId,
      };
      dispatch(setRequestParam(userpayload));
      dispatch(searchFusion());
      setSearchBarText(searchBarText.trim());
    }
  };

  //event for capturing the user clicking on the search button
  const handleClear = (event) => {
    event.preventDefault();
    setSearchBarText("");
  };

  const toggleShowOptions= (event)=>{
    event.preventDefault();
    setShowOptions(!showOptions)
  }

  return (
    <span className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
      <div className="input-group">
        <input
          type="text"
          className="form-control bg-light border-0 small"
          placeholder="Search..."
          aria-label="Search"
          value={searchBarText}
          aria-describedby="basic-addon2"
          onKeyDown={handleOnKeyDown}
          onChange={(e) => setSearchBarText(e.target.value)}
        />
        <button
          type="button"
          className="btn bg-transparent clearButton"
          onClick={handleClear}
        >
          <i className="fa fa-times"></i>
        </button>
        <div className="input-group-append">
          <button
            className="btn btn-primary"
            onClick={handleButtonPress}
            type="button"
          >
            <i className="fas fa-search fa-sm"></i>
          </button>
        </div>
        <div className="input-group-append">
         <a href="/" onClick={toggleShowOptions}>

          <i className="fas fa-cog px-2 py-2"></i>
          
          </a>
        </div>
      </div>

      {
        (showOptions)
        ?      
        <>
        <span className="btn mr-2"><SwitchOption paramName="semantic" defaultValue="true"/><span className="search_option">Semantic Search</span></span>
        </>
        :<></>
      }

    </span>
  );
}
export default QuestionTextBox;
