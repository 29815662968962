import React from "react";

function NoResults() {
  return (
    <div className="col">
      <div className="row">
        <div className="col p-0 mt-2 ">
          <div className="pt-3 mt-0">
            <div className="text-center">
              <p className="lead text-gray-800">No Results Found</p>
              <p className="text-gray-500 mb-0">
                It looks like you found a glitch in the matrix...
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoResults;
