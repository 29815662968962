import React, { useState } from "react";
import configData from "../../system_config.json";
import { getUserInfo } from "./UserSlice";
import { useSelector } from "react-redux";
import { getFusionRequestParams } from "./SearchSlice";

function ClipboardCopy({ doc }) {
  const [isCopied, setIsCopied] = useState(false);
  const requestParameters = useSelector(getFusionRequestParams);
  const querystring =
    requestParameters["q"] != null ? requestParameters["q"] : null;

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(doc.answer_t)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        clickSignal(doc, "click");
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  function clickSignal(doc, signal_type) {
    try {
      var signalHeader = new Headers();

      signalHeader.append("Content-Type", "application/json");

      //Authorization
      var username = configData.signal[0].signal_user;
      var password = configData.signal[0].signal_pswd;
      var basicAuth = "Basic " + btoa(username + ":" + password);
      signalHeader.append("Authorization", basicAuth);

      var raw = JSON.stringify([
        {
          params: {
            id: uuidv4(),
            query: querystring,
            doc_id: doc.id,
            user_id: getUserInfo.emailId,
            session: uuidv4(),
            count: 1,
            ctype: signal_type,
          },
          type: signal_type,
          timestamp: new Date().getTime(),
        },
      ]);

      var requestOptions = {
        method: "POST",
        headers: signalHeader,
        body: raw,
        redirect: "follow",
      };
      fetch(configData.signal[0].signal_host, requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.error("error", error));
    } catch (err) {
      console.error("Sending signal failed: " + err);
    }
  }

  return (
    <button
      className="btn btn-sm"
      type="button"
      onClick={handleCopyClick}
      title="Copy Answer to Clipboard"
      href="/#"
    >
      <i
        className={
          isCopied
            ? "fas fa-clipboard pr-1 text-info fa-beat"
            : "fas fa-clipboard pr-1"
        }
      ></i>
    </button>
  );
}

export default ClipboardCopy;
