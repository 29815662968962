import React from "react";
import {
  searchFusion,
  setRequestParam,
  getFusionRequestParams,
} from "../Utility/SearchSlice";
import { useDispatch, useSelector } from "react-redux";

function PaginationLink(props) {
  const dispatch = useDispatch();

  const model = props.model;
  const handleOnClick = (e) => {
    e.preventDefault();
    const payload = {
      paramName: "start",
      paramValue: e.target.getAttribute("start"),
    };
    dispatch(setRequestParam(payload));
    dispatch(searchFusion());
  };
  const cssDisabled = model.disabled ? " disabled" : "";
  const cssActive = model.active ? " active" : "";
  const cssClass = "page-item" + cssDisabled + cssActive;

  return (
    <li className={cssClass}>
      <button
        href="/#"
        className="page-link btn-secondary"
        onClick={handleOnClick}
        start={model.start}
      >
        {" "}
        {model.label}
      </button>
    </li>
  );
}

function PageControls(props) {
  const requestParameters = useSelector(getFusionRequestParams);
  const rows =
    requestParameters["rows"] != null
      ? parseInt(requestParameters["rows"])
      : 10;

  const className = props.className != null ? props.className : "float-right";
  const start =
    requestParameters != null && requestParameters.start != null
      ? parseInt(requestParameters.start)
      : 0;

  const numFound = props.numFound;
  var showPageControls = props.numFound > rows;

  const pages = parseInt(numFound / rows);

  if (props.numFound == null || props.numFound === 0) {
    showPageControls = false;
  }
  var paginationModel = [];

  const maxPages = 5;
  var limit = maxPages;
  var startDisplay = 1;
  var lastModel = null;

  if (pages > maxPages) {
    const currentPage = start / rows + 1;

    startDisplay = currentPage;
    limit = Math.min(startDisplay + maxPages, pages);
    if (limit - startDisplay < maxPages) {
      startDisplay = limit - maxPages + 1;
    }

    if (pages > limit) {
      lastModel = {
        label: "Last",
        disabled: false,
        start: (pages - 1) * rows,
        active: false,
      };
    }

    var displayFirstButton = false;
    if (limit - maxPages > 1) {
      displayFirstButton = true;
    }
    if (displayFirstButton) {
      /*************Build the model for Previous ***/
      const firstModel = {
        label: "First",
        disabled: false,
        start: 0,
        active: false,
      };
      paginationModel.push(firstModel);
    }
  }

  /*************Build the model for Previous ***/
  const prevStart = start - rows;
  const prevDisabled = prevStart < 0;
  const prevModel = {
    label: "<",
    disabled: prevDisabled,
    start: prevStart,
    active: false,
  };
  paginationModel.push(prevModel);

  for (var i = startDisplay; i <= pages && i < limit; i++) {
    const startNum = (i - 1) * rows;
    const labelTxt = i; //(i) + " " + startNum;
    const isActive = startNum === start;
    const pageModel = {
      label: labelTxt,
      disabled: false,
      start: startNum,
      active: isActive,
    };
    paginationModel.push(pageModel);
  }

  /*************Build the model for Next ***/
  const nextStart = start + rows;
  const nextDisabled = nextStart >= props.numFound;
  const nextModel = {
    label: ">",
    disabled: nextDisabled,
    start: nextStart,
    active: false,
  };

  paginationModel.push(nextModel);

  if (lastModel != null) {
    paginationModel.push(lastModel);
  }

  return (
      <React.Fragment>
        {showPageControls ? (
          <ul className="pagination float-right">
            {paginationModel.map((m) => (
              <PaginationLink key={"paginationlink-" + m.label} model={m} />
            ))}
          </ul>
        ) : (
          ""
        )}
      </React.Fragment>
  );
}

export default PageControls;
