import React, { useState } from "react";
import {
  useGetQuestionsQuery,
  useGetRFPByIDQuery,
  useDeleteRFPMutation,
  useEditRFPMutation,
} from "../Utility/RFP_API";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { ListQuestionsBulk } from "./ListQuestionsBulk";
import { ListQuestions } from "./ListQuestions";
import { useParams } from "react-router-dom";
import { Outlet } from "react-router-dom";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import NewQuestion from "./NewQuestion";
import { Spinner, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import QuestionsManageSearch from "./QuestionsManageSearch";

export function RFP(props) {
  const params = useParams();
  const rfp_id = params.rfp_id;

  return (
    <>
      <FetchRFP rfp_id={rfp_id} />
      <Outlet />
    </>
  );
}

function RFPQuestions(params) {
  const rfp_id = params.rfp_id;
  const { data, error, isLoading } = useGetQuestionsQuery(rfp_id);
  return (
    <>
      <div>
        {error ? (
          <>
            <div className="alert alert-danger" role="alert">
              An error occurred: {error.error}
            </div>
          </>
        ) : isLoading ? (
          <Button variant="primary" disabled>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Loading...
          </Button>
        ) : data ? (
          <>
            <ListQuestions questions={data} rfp_id={rfp_id} />
          </>
        ) : null}
      </div>
      <Outlet />
    </>
  );
}

function RFPQuestionsBulk(params) {
  const rfp_id = params.rfp_id;
  const { data, error, isLoading } = useGetQuestionsQuery(rfp_id);
  return (
    <>
      <div>
        {error ? (
          <>
            <div className="alert alert-danger" role="alert">
              An error occurred: {error.error}
            </div>
          </>
        ) : isLoading ? (
          <>Loading...</>
        ) : data ? (
          <>
            <ListQuestionsBulk questions={data} rfp_id={rfp_id} />
          </>
        ) : null}
      </div>
      <Outlet />
    </>
  );
}

function RFPQuestionsSearch(params) {
  const rfp_id = params.rfp_id;
  const { data, error, isLoading } = useGetQuestionsQuery(rfp_id);
  return (
    <>
      <div>
        {error ? (
          <>
            <div className="alert alert-danger" role="alert">
              An error occurred: {error.error}
            </div>
          </>
        ) : isLoading ? (
          <>Loading...</>
        ) : data ? (
          <>
            <QuestionsManageSearch questions={data} rfp_id={rfp_id} />
          </>
        ) : null}
      </div>
      <Outlet />
    </>
  );
}

function FetchRFP(params) {
  const rfp_id = params.rfp_id;
  const { data, error, isLoading } = useGetRFPByIDQuery(rfp_id);
  return (
    <>
      <div>
        {error ? (
          <>
            <div className="alert alert-danger" role="alert">
              An error occurred: {error.error}
            </div>
          </>
        ) : isLoading ? (
          <Button variant="primary" disabled>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Loading...
          </Button>
        ) : data ? (
          <>
            <RFPDetail data={data} rfp_id={rfp_id} />
          </>
        ) : null}
      </div>
      <Outlet />
    </>
  );
}

function RFPDetail(params) {
  const rfp_id = params.rfp_id;
  const data = params.data;
  const [company, setCompany] = React.useState(data.company);
  const [name, setName] = React.useState(data.name);
  const [initiative, setInitiative] = React.useState(data.initiative);
  const [key, setKey] = useState("questions");
  const [message, setMessage] = useState("");
  const [deleteRFPNameConfirmation, setDeleteRFPNameConfirmation] =
    useState("");
  const [editRFPMutationTrigger] = useEditRFPMutation();
  const [deleteRFPMutationTrigger] = useDeleteRFPMutation();

  const editRFP = (rfp_id, body) => (event) => {
    event.preventDefault();
    editRFPMutationTrigger({ rfp_id, body });
  };

  const navigate = useNavigate();

  const deleteRFP = (rfp_id, rfpNameConfirmation, rfpName) => (event) => {
    if (rfpName !== rfpNameConfirmation) {
      event.preventDefault();
      setMessage("Please fill in the RFP Name in order to delete the RFP");
    } else {
      deleteRFPMutationTrigger({ rfp_id });
      navigate("/rfp_list");
    }
  };

  return (
    <>
      <>
        {/* <div className="row py-4">
          <div className="col">
            <Breadcrumb />

            <h1>{name}</h1>
            <h6>{company}</h6>
            <p>{initiative}</p>
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
              mountOnEnter={true}
            >
              <Tab eventKey="questions" title="Questions">
                <RFPQuestions rfp_id={rfp_id} />
              </Tab>

              <Tab eventKey="add" title="Add Question">
                <div className=".container-md" id="add-rfp">
                  <NewQuestion
                    rfp_id={rfp_id}
                    next_sequence={0}
                    setTabKey={setKey}
                  />
                </div>
              </Tab>
              <Tab eventKey="bulk" title="Bulk Add/Edit">
                <RFPQuestionsBulk rfp_id={rfp_id} />
              </Tab>
              <Tab eventKey="edit" title="Edit RFP">
                <div className=".container-md" id="add-rfp">
                  <form
                    onSubmit={editRFP(rfp_id, {
                      company: company,
                      name: name,
                      initiative: initiative,
                    })}
                  >
                    <div className="form-group row">
                      <label className="col-sm-1 col-form-label">Company</label>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          value={company}
                          onChange={(e) => setCompany(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-1 col-form-label">Name</label>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-1 col-form-label">
                        Initiative
                      </label>
                      <div className="col-sm-6">
                        <textarea
                          className="form-control"
                          rows="10"
                          value={initiative}
                          onChange={(e) => setInitiative(e.target.value)}
                        ></textarea>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-10">
                        <button type="submit" className="btn btn-primary">
                          {" "}
                          Save{" "}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Tab>
              <Tab eventKey="delete" title="Delete RFP">
                <div className="alert alert-warning" role="alert">
                  This will permanently delete the RFP and all its questions.
                  Proceed with caution.
                </div>
                <div className="my-2">
                  In order to delete. Type in the RFP Name:
                </div>
                <div className="font-italic my-4 no-copy">{name}</div>
                <div className="my-2">in the input field below.</div>
                <form className="form-inline">
                  <label className="sr-only">Name</label>
                  <input
                    type="text"
                    className="form-control mb-2 mr-md-2"
                    placeholder="Type in RFP name here"
                    onChange={(e) =>
                      setDeleteRFPNameConfirmation(e.target.value)
                    }
                  />
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={deleteRFP(rfp_id, deleteRFPNameConfirmation, name)}
                  >
                    {" "}
                    Delete{" "}
                  </button>
                </form>
                {message !== "" ? (
                  <div className="alert alert-primary" role="alert">
                    {message}
                  </div>
                ) : (
                  <></>
                )}
              </Tab>
              <Tab eventKey="search" title="Submit for Search">
                <>
                  <RFPQuestionsSearch rfp_id={rfp_id} />
                </>
              </Tab>
            </Tabs>
          </div>
        </div> */}
        <div id="content">
          <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            <button
              id="sidebarToggleTop"
              className="btn btn-link d-md-none rounded-circle mr-3"
            >
              <i className="fa fa-bars"></i>
            </button>
            <div className="content-fluid">
              <h1 className="h3 mb-2 text-gray-800">Edit RFP</h1>
            </div>

            <ul className="navbar-nav ml-auto">
              <div className="topbar-divider d-none d-sm-block"></div>
              <li className="nav-item dropdown no-arrow">
                <a
                  className="nav-link dropdown-toggle"
                  href="/#"
                  id="userDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                    {/* {props.userInfo.givenName} {props.userInfo.familyName} */}
                  </span>
                  {/* <img
                  className="img-profile rounded-circle"
                  referrerPolicy="no-referrer"
                  src={props.userInfo.imageURL}
                /> */}
                </a>

                <div
                  className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                  aria-labelledby="userDropdown"
                >
                  <a
                    className="dropdown-item"
                    href="/#"
                    data-toggle="modal"
                    data-target="#logoutModal"
                  >
                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                    Logout
                  </a>
                </div>
              </li>
            </ul>
          </nav>
          <div className="container-fluid">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h2 className="m-0 font-weight-bold text-primary">{name}</h2>
                <h6>{company}
                {" - " + initiative}</h6>
              </div>
              <div className="card-body">
              <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
              mountOnEnter={true}
            >
              <Tab eventKey="questions" title="Questions">
                <RFPQuestions rfp_id={rfp_id} />
              </Tab>

              <Tab eventKey="add" title="Add Question">
                <div className=".container-md" id="add-rfp">
                  <NewQuestion
                    rfp_id={rfp_id}
                    next_sequence={0}
                    setTabKey={setKey}
                  />
                </div>
              </Tab>
              <Tab eventKey="bulk" title="Bulk Add/Edit">
                <RFPQuestionsBulk rfp_id={rfp_id} />
              </Tab>
              <Tab eventKey="edit" title="Edit RFP">
                <div className=".container-md" id="add-rfp">
                  <form
                    onSubmit={editRFP(rfp_id, {
                      company: company,
                      name: name,
                      initiative: initiative,
                    })}
                  >
                    <div className="form-group row">
                      <label className="col-sm-1 col-form-label">Company</label>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          value={company}
                          onChange={(e) => setCompany(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-1 col-form-label">Name</label>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-1 col-form-label">
                        Initiative
                      </label>
                      <div className="col-sm-6">
                        <textarea
                          className="form-control"
                          rows="10"
                          value={initiative}
                          onChange={(e) => setInitiative(e.target.value)}
                        ></textarea>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-10">
                        <button type="submit" className="btn btn-primary">
                          {" "}
                          Save{" "}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Tab>
              <Tab eventKey="delete" title="Delete RFP">
                <div className="alert alert-warning" role="alert">
                  This will permanently delete the RFP and all its questions.
                  Proceed with caution.
                </div>
                <div className="my-2">
                  In order to delete. Type in the RFP Name:
                </div>
                <div className="font-italic my-4 no-copy">{name}</div>
                <div className="my-2">in the input field below.</div>
                <form className="form-inline">
                  <label className="sr-only">Name</label>
                  <input
                    type="text"
                    className="form-control mb-2 mr-md-2"
                    placeholder="Type in RFP name here"
                    onChange={(e) =>
                      setDeleteRFPNameConfirmation(e.target.value)
                    }
                  />
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={deleteRFP(rfp_id, deleteRFPNameConfirmation, name)}
                  >
                    {" "}
                    Delete{" "}
                  </button>
                </form>
                {message !== "" ? (
                  <div className="alert alert-primary" role="alert">
                    {message}
                  </div>
                ) : (
                  <></>
                )}
              </Tab>
              <Tab eventKey="search" title="Submit for Search">
                <>
                  <RFPQuestionsSearch rfp_id={rfp_id} />
                </>
              </Tab>
            </Tabs>
                </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}
