
import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import { useGetRFPByIDQuery, useGetQuestionByIDQuery } from '../Utility/RFP_API'


export default function Breadcrumb(props) {

    let location = useLocation();
    let routeParams = useParams();


    return (
        <>
            <nav aria-label="breadcrumb" >
                <ol className="breadcrumb rfp_breadcrumb" id="rfp_breadcrumb_ol">
                    <li className="breadcrumb-item active" >  <Link to="/">Home</Link></li>
                    <RFPsCrumb location={location} />
                    <RFPCrumb location={location} routeParams={routeParams} />
                    <QuestionCrumb location={location} routeParams={routeParams} />                    
                </ol>
            </nav>
        </>
    )
}

function RFPsCrumb(props) {
    const pathname = props.location.pathname;
    return (
        <>
            {pathname.startsWith("/rfp_list")
                ? pathname === "/rfp_list"
                    ? <li className="breadcrumb-item">RFP's</li>
                    : <li className="breadcrumb-item" aria-current="page"><Link to="/rfp_list">RFP's</Link></li>
                : <></>
            }
        </>
    )
}

function RFPCrumb(props) {
    const location = props.location;
    const routeParams = props.routeParams;
    const rfpId = routeParams['rfp_id'];
    return (
        <>
            {rfpId != null
                ? <RFPCrumbHelper location={location} routeParams={routeParams} />
                : <></>
            }
        </>
    )
}


function RFPCrumbHelper(props) {
    const routeParams = props.routeParams;
    const rfpId = routeParams['rfp_id'];
    const questionId = routeParams['question_id'];
    const { data, error, isLoading } = useGetRFPByIDQuery(rfpId)
    return (
        <>
            {error ? (
                <></>
            ) : isLoading ? (
                <></>
            ) : data ? (
                <>
                    {questionId == null
                        ? <li className="breadcrumb-item active" aria-current="page">{data.name}</li>
                        : <li className="breadcrumb-item" aria-current="page"><Link to={"/rfp_list/" + rfpId}>{data.name}</Link></li>
                    }
                </>
            ) : null}
        </>
    )
}



function QuestionCrumb(props) {
    const location = props.location;
    const routeParams = props.routeParams;
    const questionId = routeParams['question_id'];
    return (
        <>
            {questionId != null
                ? <QuestionCrumbHelper location={location} routeParams={routeParams} />
                : <></>
            }
        </>
    )
}


function QuestionCrumbHelper(props) {
    const routeParams = props.routeParams;
    const rfpId = routeParams['rfp_id'];
    const questionId = routeParams['question_id'];
    const { data, error, isLoading } = useGetQuestionByIDQuery({ rfp_id: rfpId, question_id: questionId })

    return (
        <>
            {error ? (
                <></>
            ) : isLoading ? (
                <></>
            ) : data ? (
                <>
                    <li className="breadcrumb-item active" aria-current="page">{data.question}</li>
                </>
            ) : null}
        </>
    )
}