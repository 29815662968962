import React, { useState } from 'react';
import {
  setRequestParam
} from '../Utility/SearchSlice';
import { useDispatch } from "react-redux";

export function SwitchOption(props) {
  const defaultValue = props.defaultValue;
  const paramName = props.paramName;

  const [toggle, setToggle] = useState(Boolean(defaultValue));
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    const newValue = !toggle ;
    setToggle(newValue);
    const param  = { paramName: paramName, paramValue: String(newValue) }
    dispatch(setRequestParam(param))
  };


  return (
    <React.Fragment>
      <input className="form-check-input" type="checkbox" checked={toggle} onChange={handleInputChange}/>
    </React.Fragment>
  )
}
export default SwitchOption