import { createSlice } from '@reduxjs/toolkit';

export const UserSlice = createSlice({
  name: 'user',
  initialState: {
    userInfo: null,
    idToken: null,
  },
  reducers: {
    setUserInfoInSlice: (state, action) => {
      state.userInfo = action.payload;
    },
    setIdToken: (state,action)=>{
      state.idToken = action.payload;
    }
  }
});

export const {setUserInfoInSlice, setIdToken, } = UserSlice.actions;

export default UserSlice.reducer;

export const getUserInfo = state => {
  if(state  && state.user){
    return state.user.userInfo
  }
}

export const getIdToken = state => {
  if(state  && state.user && state.user.idToken){
    return state.user.idToken;
  }
}
