import React, { useState, useEffect } from 'react';
import { Outlet } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useGetQuestionByIDQuery, useEditQuestionMutation } from '../Utility/RFP_API'
import { useSearchQuery } from '../Utility/Search_API'
import { Spinner, Button } from 'react-bootstrap';
import Breadcrumb from './Breadcrumb';
import QuestionTextBox from '../UI/QuestionTextBox';
import ResponseResults from '../Results/ResponseResults';

export function Question() {
  const params = useParams();
  const question_id = params.question_id;
  const rfp_id = params.rfp_id;

  return (
    <>
      <div>
        < FetchQuestion rfp_id={rfp_id} question_id={question_id} />
      </div>
    </>
  );
}

function SearchForAnswers(params) {
  const question = params.question;
  const rfp_id = params.rfp_id;
  const question_id = params.question_id;
  const setAnswer = params.setAnswer;

  const { data, error, isLoading } = useSearchQuery(question)
  return (
    <>
      <div>
        {error ? (
          <div className="alert alert-warning" role="alert">
            An error occurred {JSON.stringify(error)}
          </div>
        ) : isLoading ? (
          <Button variant="primary" disabled>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Loading...
          </Button>

        ) : data ? (
          <>
            <SearchResults data={data} question_id={question_id} rfp_id={rfp_id} setAnswer={setAnswer} />
          </>
        ) : null}
      </div>

    </>
  )
}


function SearchResults(params) {
  const data = params.data;
  const rfp_id = params.rfp_id;
  const question_id = params.question_id;
  const setAnswer = params.setAnswer;

  return (
    <>
      <table className="table">
        <thead className="thead-light">
          <tr>
            <th scope="col">Question</th>
            <th scope="col">Answer</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {
            data.response.docs.map((doc) => {
              return <ResultItem key={doc.id} doc={doc} question_id={question_id} rfp_id={rfp_id} setAnswer={setAnswer} />
            })
          }
        </tbody>
      </table>

    </>
  )
}

function ResultItem(params) {
  const doc = params.doc;
  const setAnswer = params.setAnswer;

  const callSetAnswer = (answer) => (event) => {
    setAnswer(answer);
  }

  return (
    <>
      <tr>
        <td>{doc.question_t}</td>
        <td>{doc.answer_t}</td>
        <td><button onClick={callSetAnswer(doc.answer_t)}>Select</button></td>
      </tr>
    </>
  )
}



function FetchQuestion(params) {
  const rfp_id = params.rfp_id;
  const question_id = params.question_id;

  const { data, error, isLoading } = useGetQuestionByIDQuery({ rfp_id, question_id })
  return (
    <>
      <div>
        {error ? (
        <>
        <div className="alert alert-danger" role="alert">
            An error occurred: {error.error}
        </div>
        </>
        ) : isLoading ? (
          <Spinner animation="border" variant="primary" />
        ) : data ? (
          <>
            <QuestionDetail data={data} rfp_id={rfp_id} question_id={question_id} />
          </>
        ) : null}
      </div>
      <Outlet />
    </>
  );
}

function QuestionDetail(params) {
  const rfp_id = params.rfp_id;
  const question_id = params.question_id;
  const data = params.data;



  const [question, setQuestion] = useState((data.question!=null)?data.question:"");
  const [answer, setAnswer] = useState(data.answer!=null?data.answer:"")
  const [category, setCategory] = useState(data.category!=null?data.category:"");
  const [sequence, setSequence] = useState(data.sequence!=null?data.sequence:"");
  const [unsaved, setUnsaved] = useState(false);
  const [message, setMessage] = useState("");

  const [editQuestionTrigger] = useEditQuestionMutation();


  const editQuestion = (rfp_id, question_id, body) => (event) => {
    event.preventDefault();
    editQuestionTrigger({ rfp_id, question_id, body });
    setUnsaved(false);
    setMessage("Your changes have been saved");
  }

  const callSetAnswer = (answer) => {
    console.log("callSetAnswer called " + answer);
    setAnswer(answer);
    setUnsaved(true);
  }


  return (
    <div className="row py-4">
      <div className="col">
        <Breadcrumb/>
        <h1>{question}</h1>
        <div>
          <div className=".container-md" id="add-rfp">
            <form onSubmit={editQuestion(rfp_id, question_id,
              {
                question: question,
                answer: answer,
                category: category,
                sequence: parseInt(sequence)
              }
            )}>
              <div className="form-group row">
                <label className="col-sm-1 col-form-label">Category</label>
                <div className="col-sm-6">
                  <input type="text" className="form-control" value={category} onChange={(e) => { setCategory(e.target.value); setUnsaved(true); setMessage("")  }} />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-1 col-form-label">Sequence</label>
                <div className="col-sm-6">
                  <input type="text" className="form-control" value={sequence} onChange={(e) => { setSequence(e.target.value); setUnsaved(true) ; setMessage("") }} />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-1 col-form-label">Question</label>
                <div className="col-sm-6">
                  <textarea className="form-control" rows="3" value={question} onChange={(e) => { setQuestion(e.target.value); setUnsaved(true); setMessage("") }}></textarea>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-1 col-form-label">Answer</label>
                <div className="col-sm-6">
                  <textarea className="form-control" rows="3" value={answer} onChange={(e) => { setAnswer(e.target.value); setUnsaved(true) ; setMessage("")}}></textarea>
                </div>
              </div>

              {
                (unsaved)
                  ? <div className="alert alert-warning" role="alert">
                    You have unsaved changes.  Press on the Save button to save.
                  </div>
                  : <></>
              }

              {
                (message !== "")
                  ? <div className="alert alert-success" role="alert">
                    {message}
                  </div>
                  : <></>
              }

              <div className="form-group row">
                <div className="col-sm-10">
                  <button type="submit" className="btn btn-primary"> Save </button>
                </div>

              </div>
            </form>
          </div>

          <SearchForQuestion query={question} question_id={question_id} rfp_id={rfp_id} setAnswer={callSetAnswer} />
        </div>
      </div>
    </div>
  );
}


function SearchForQuestion(props) {
  useEffect(() => {
    console.log("query is " + props.query)
  }, [props.query]);

  const [searchTerm, setSearchTerm] = useState(String(props.query))
  const setAnswer = props.setAnswer

  return(
    <>
      <hr/>
      <h2>Search for Answers</h2>
      <div className="row">
                <div className="col">
                  <div
                    className="card shadow w-100 pt-1 mt-0"
                    id="question_wrapper"
                  >
                    <article className="card-body">
                      <QuestionTextBox emailId="eric.fordelon@lucidworks.com" query={searchTerm}/>
                    </article>
                  </div>
                  {/* <div className="col"> */}
                  <ResponseResults setAnswer={setAnswer} />
                  {/* </div> */}
                </div>
              </div>
    </>
  )
}