import React from "react";
import configData from "../../system_config.json";
import { getUserInfo } from "./UserSlice";
import { useSelector } from "react-redux";
import { getFusionRequestParams } from "./SearchSlice";

function Thumbs({ doc }) {
  const requestParameters = useSelector(getFusionRequestParams);
  const querystring =
    requestParameters["q"] != null ? requestParameters["q"] : null;

  // ßconst userInfo = useSelector(getUserInfo);

  // async function copyTextToClipboard(text) {
  //   if ("clipboard" in navigator) {
  //     return await navigator.clipboard.writeText(text);
  //   } else {
  //     return document.execCommand("copy", true, text);
  //   }
  // }
  const handleClick = () => {
    // Asynchronously call copyTextToClipboard
    clickSignal(doc);
    // .then(() => {
    //   // If successful, update the isCopied state value
    //   // setIsCopied(true);

    //   setTimeout(() => {
    //     // setIsCopied(false);
    //   }, 1500);
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
  };

  function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  function clickSignal(doc) {
    try {
      var signalHeader = new Headers();
      signalHeader.append("Content-Type", "application/json");

      //Authorization
      var username = configData.signal[0].signal_user;
      var password = configData.signal[0].signal_pswd;
      var basicAuth = "Basic " + btoa(username + ":" + password);
      signalHeader.append("Authorization", basicAuth);

      var raw = JSON.stringify([
        {
          params: {
            id: uuidv4(),
            query: querystring,
            doc_id: doc.id,
            user_id: getUserInfo.emailId,
            session: uuidv4(),
            count: configData.signal[0].signals_per_click,
            ctype: "click",
          },
          type: "click",
          timestamp: new Date().getTime(),
        },
      ]);

      var requestOptions = {
        method: "POST",
        headers: signalHeader,
        body: raw,
        redirect: "follow",
      };
      fetch(configData.signal[0].signal_host, requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.error("error", error));
    } catch (err) {
      console.error("Sending signal failed: " + err);
    }

    return true;
  }

  return (
    <button
    className="btn btn-sm"
      type="button"
      onClick={handleClick}
      title="Thumbs Up"
      href="/#"
    >
            <span >
            <i className="fa fa-thumbs-up" aria-hidden="true"></i>
      </span>

    </button>
  );
}

export default Thumbs;
