import React, { useState, useEffect } from "react";
import {
  useRfp_indexed_questions_allQuery,
  useDeleteQuestionsMutation,
} from "../Utility/Search_API";
import {
  useIndexAllQuestionsMutation,
  useRunJobMutation,
} from "../Utility/RFP_API";
import BootstrapTable from "react-bootstrap-table-next";
import { useGetRFPSQuery } from "../Utility/RFP_API";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

export default function SearchIndexManagement(props) {
  return (
    <>
      {" "}
      <p className="mb-4">
        This page will have various utilities related to managing RFP's and
        their Searchability in Fusion. In addition, RFP pages will have their
        own "Submit for Search" section for submitting questions and answers to
        Fusion for search.
      </p>
      {/* <div className="py-3">
                <h1>RFP Search Index Administration</h1>
                <p>This page will have various utilities related to managing RFP's and their Searchability in Fusion.  In addition, RFP pages will have their own "Submit for Search" section for submitting questions and answers to Fusion for search.</p>
            </div> */}
      <div className="row">
        <div className="col-lg d-flex align-items-stretch">
          <div className="card">
            <div className="card-header"><h6 className="m-0 font-weight-bold text-secondary">Index Counts</h6></div>
            <div className="card-body">
              <IndexedRecordCounts />
            </div>
          </div>
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-lg-6 d-flex align-items-stretch">
          <div className="card h-100 w-100">
            <div className="card-header"><h6 className="m-0 font-weight-bold text-secondary">Index to Fusion</h6></div>
            <div className="card-body">
              <Reindex />
            </div>
          </div>
        </div>
        <div className="col-lg-6 d-flex align-items-stretch">
          <div className="card h-100">
            <div className="card-header"><h6 className="m-0 font-weight-bold text-secondary">Clear Milvus</h6></div>
            <div className="card-body">
            <ClearMilvusCollection />
            </div>
          </div>
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-lg d-flex align-items-stretch">
          <div className="card">
            <div className="card-header">
              <h6 className="m-0 font-weight-bold text-secondary">Orphaned Questions</h6>
              </div>
            <div className="card-body">
            <OrphanedFusionDocsContainer />
            </div>
          </div>
        </div>
      </div>
   
    </>
  );
}

function IndexedRecordCounts(props) {
  const { data, error, isLoading } = useRfp_indexed_questions_allQuery({
    fieldsList: "id,question_t,answer_t,rfp_id_s,category_s",
    rows: 0,
  });
  const {
    data: rfpsData,
    error: rfpsError,
    isLoading: rfpsIsLoading,
  } = useGetRFPSQuery();
  return (
    <>
      {error || rfpsError ? (
        <>
          <div className="alert alert-danger" role="alert">
            An error occurred: {error.error}
          </div>
        </>
      ) : isLoading || rfpsIsLoading ? (
        <>Loading ...</>
      ) : data && rfpsData ? (
        <>
          <IndexedRecordCountsDisplay solrResponse={data} rfps={rfpsData} />
        </>
      ) : null}
    </>
  );
}

export function IndexedRecordCountsDisplay(props) {
  const solrResponse = props.solrResponse;
  const rfp_id_facets =
    solrResponse != null &&
    solrResponse.facet_counts.facet_fields &&
    solrResponse.facet_counts.facet_fields.rfp_id_s
      ? solrResponse.facet_counts.facet_fields.rfp_id_s
      : [];

  //Read Facets from Solr Response and build a map rfp_counts_map keyed by rfp_id
  const rfp_counts_from_fusion = rfp_id_facets.map((facet_value) => {
    return {
      rfp_id: facet_value[0],
      count: facet_value[1],
      rfp_name: "tbd",
    };
  });
  const rfp_counts_map = rfp_counts_from_fusion.reduce((amap, val) => {
    amap[val.rfp_id] = val;
    return amap;
  }, {});

  const rfps = props.rfps;
  const rfpsWithCount = rfps.map((element) => {
    const thecount =
      rfp_counts_map[element.id] && rfp_counts_map[element.id].count
        ? rfp_counts_map[element.id].count
        : 0;
    return { ...element, count: thecount };
  });

  const totalCount = rfpsWithCount.reduce((count, mapEntry) => {
    return (count += mapEntry.count);
  }, 0);

  const columns = [
    {
      dataField: "company",
      text: "Company",
      sort: true,
      headerClasses: "category-column",
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerClasses: "question-column",
      headerStyle: (colum, colIndex) => {
        return { width: "70%" };
      },
      formatter: (cellContent, row) => {
        return (
          <>
            <span>
              <Link to={'/rfp_list/' + row.id}>{row.name}</Link>
            </span>
          </>
        );
      },
    },
    {
      dataField: "count",
      text: "Docs",
      sort: true,
      headerClasses: "question-column",
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
  ];

  return (
    <>
      <div className="font-weight-bold pb-2">Total Count: {totalCount}</div>

      <BootstrapTable
        keyField="id"
        data={rfpsWithCount}
        columns={columns}
        bootstrap4
        striped
        hover
        condensed
        tabIndexCell
      />
    </>
  );
}

function Reindex(props) {
  const [indexAllTrigger, { isLoading: indexAllIsLoading }] =
    useIndexAllQuestionsMutation();
  const [message, setMessage] = useState();

  const handleIndexButton = (event) => {
    indexAllTrigger()
      .unwrap()
      .then((payload) => {
        console.log("fulfilled", payload);
        setMessage({
          status: "success",
          message: "Successfully Indexed ",
        });
      })
      .catch((error) => {
        console.error("rejected", error);
        setMessage({
          status: "error",
          message: "An error has occurred: " + JSON.stringify(error),
        });
      });
  };

  return (
    <>
      <p>Submit all RFP questions that are marked as searchable to Fusion</p>
      <div className="py-2 px-2">
        <div className="btn-group" role="group" aria-label="Basic example">
          {indexAllIsLoading ? (
            <>
              <button type="button" className="btn btn-primary" disabled>
                Submit Questions to Fusion
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleIndexButton}
              >
                Submit Questions to Fusion
              </button>
            </>
          )}
        </div>
        <IndexAllMessageDisplay message={message} />
      </div>
    </>
  );
}

function ClearMilvusCollection(props) {
  const [clearMilvusTrigger, { isLoading: clearMilvusIsLoading }] =
    useRunJobMutation();
  const [message, setMessage] = useState();
  const [showPrompt, setShowPrompt] = useState(false);

  const handleClearMilvusButton = (event) => {
    setShowPrompt(true);
  };

  const handleClearMilvusCollection = async () => {
    const runJobParam = {
      jobname: "spark:deploy_milvus_rfp_tool",
      status_retries: 5,
      retry_sleep: 20,
    };
    clearMilvusTrigger(runJobParam)
      .unwrap()
      .then((payload) => {
        console.log("fulfilled", payload);
        setMessage({
          status: "success",
          message: "Successfully Cleared Milvus Collection ",
        });
      })
      .catch((error) => {
        console.error("rejected", error);
        setMessage({
          status: "error",
          message: "An error has occurred: " + JSON.stringify(error),
        });
      });
  };

  return (
    <>
      <ClearMilvusCollectionPrompt
        show={showPrompt}
        setShow={setShowPrompt}
        clearMilvusTrigger={clearMilvusTrigger}
        clearMilvusIsLoading={clearMilvusIsLoading}
        setMessage={setMessage}
        handleClearMilvusCollection={handleClearMilvusCollection}
      />
      <p>
        Due to a bug, there may be a need to clear milvus collection from time
        to time.{" "}
      </p>
      <p className="font-weight-bold">
        Note that this will disable Search. Make sure you "Index to Fusion"
        immediately right after clearing Milvus to re-enable Search.
      </p>
      <div className="py-2 px-2">
        <div className="btn-group" role="group" aria-label="Basic example">
          {clearMilvusIsLoading ? (
            <>
              <button type="button" className="btn btn-secondary" disabled>
                Clear Milvus Collection
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleClearMilvusButton}
              >
                Clear Milvus Collections
              </button>
            </>
          )}
        </div>
        <IndexAllMessageDisplay message={message} />
      </div>
    </>
  );
}

function ClearMilvusCollectionPrompt(props) {
  const setShow = props.setShow;
  const handleClearMilvusCollection = props.handleClearMilvusCollection;

  const handleCancel = (event) => {
    event.preventDefault();
    setShow(false);
  };

  const handleConfirmation = (event) => {
    event.preventDefault();
    handleClearMilvusCollection();
    setShow(false);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="p-5 text-center">
          <h4>Are you sure you want to clear Milvus Collection?</h4>
          <div className="alert alert-warning" role="alert">
            <p>
              Note that this will disable Search. Make sure you "Index to
              Fusion" immediately right after clearing Milvus to re-enable
              Search.
            </p>
          </div>

          <div className="py-3">
            <div className="btn-group" role="group" aria-label="Basic example">
              <button
                type="button"
                className="btn btn-primary mr-2"
                onClick={handleConfirmation}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-primary mr-2"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function IndexAllMessageDisplay(props) {
  const message = props.message;
  const divClassName =
    message != null && message.status === "success"
      ? "alert alert-success"
      : "alert alert-danger";
  return (
    <>
      {message == null ? (
        <></>
      ) : (
        <>
          <div className="py-2 px-2">
            <div class={divClassName}>{message.message}</div>
          </div>
        </>
      )}
    </>
  );
}

export function OrphanedFusionDocsContainer(props) {
  const { data, error, isLoading } = useRfp_indexed_questions_allQuery({
    fieldsList: "id,question_t,answer_t,rfp_id_s,category_s",
    rows: 1000,
  });
  const {
    data: rfpsData,
    error: rfpsError,
    isLoading: rfpsIsLoading,
  } = useGetRFPSQuery();
  return (
    <>
      {error || rfpsError ? (
        <>
          <div className="alert alert-danger" role="alert">
            An error occurred: {error.error}
          </div>
        </>
      ) : isLoading || rfpsIsLoading ? (
        <>Loading ...</>
      ) : data && rfpsData ? (
        <>
          <OrphanedFusionDocs solrResponse={data} rfps={rfpsData} />
        </>
      ) : null}
    </>
  );
}

export function OrphanedFusionDocs(props) {
  const solrResponse = props.solrResponse;
  const solrDocs =
    solrResponse != null && solrResponse.response && solrResponse.response.docs
      ? solrResponse.response.docs
      : [];
  const [selected, setSelected] = useState({});
  const [selectedEmpty, setSelectedEmpty] = useState(true);
  const [deleteQuestionTrigger] = useDeleteQuestionsMutation();
  const rfps = props.rfps;
  const rfpIds = rfps.reduce((a, v) => ({ ...a, [v["id"]]: 1 }), {});
  const orphanedFusionDocs = solrDocs.filter(
    (solrDoc) => rfpIds[solrDoc.rfp_id_s] == null
  );

  useEffect(() => {
    if (Object.keys(selected).length > 0) {
      setSelectedEmpty(false);
    } else {
      setSelectedEmpty(true);
    }
  }, [selected]);

  const columns = [
    {
      dataField: "id",
      text: "ID",
      headerStyle: () => {
        return { width: "20%" };
      },
      sort: true,
    },
    {
      dataField: "rfp_id_s",
      text: "RFP ID",
      headerStyle: () => {
        return { width: "20%" };
      },
      sort: true,
    },
    {
      dataField: "category_s",
      text: "Category",
      headerStyle: () => {
        return { width: "20%" };
      },
      sort: true,
    },
    {
      dataField: "question_t",
      text: "Question",
      headerStyle: () => {
        return { width: "20%" };
      },
      sort: true,
    },
    {
      dataField: "answer_t",
      text: "Answer",
      headerStyle: () => {
        return { width: "40%" };
      },
    },
  ];

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      const newSelection = { ...selected, [row.id]: 1 };
      setSelected(newSelection);
    } else {
      const newSelection = { ...selected };
      delete newSelection[row.id];
      setSelected(newSelection);
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    if (isSelect) {
      const ids = rows.map((r) => r.id);
      //convert ids which is an array of strings to Object/Map where the id is the key, and value is 1
      const newSelection = ids.reduce((a, v) => ({ ...a, [v]: 1 }), {});
      setSelected(newSelection);
    } else {
      setSelected({});
    }
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
  };

  const handleDelete = (event) => {
    event.preventDefault();
    const question_ids = Object.keys(selected);
    const deletePayload = question_ids.map((question_id) => {
      return { id: question_id };
    });
    deleteQuestionTrigger({ body: deletePayload });
  };

  return (
    <>
      <div className="py-3">
        <p>
          These questions in Fusion refer to rfp_id's that do not exist in the
          RFP Database. They are good candidates for deletion from the Index.
        </p>
      </div>
      <div className="py-2 px-2">
        <div className="btn-group" role="group" aria-label="Basic example">
          <button
            type="button"
            className="btn btn-secondary"
            disabled={selectedEmpty}
            onClick={handleDelete}
          >
            Delete from Index
          </button>
        </div>
      </div>
      <div>
        <BootstrapTable
          keyField="id"
          data={orphanedFusionDocs}
          columns={columns}
          selectRow={selectRow}
          striped
        />
      </div>
    </>
  );
}
