import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import configData from "../../system_config.json";
import { v4 as uuidv4 } from 'uuid';

export const searchFusion = createAsyncThunk(
  'search/submit',
  async (userData, { getState, rejectWithValue }) => {
    let { search } = getState();
    let params = new URLSearchParams();
   
    if (search.request.params['q'] != null) {
      for (const key in search.request.params) {
        params.append(key, search.request.params[key]);
      }

      for (const key in search.request.filters) {
        const filterValues = search.request.filters[key];
        if (filterValues.length > 0) {
          var first = true;
          var valueStr = "";
          for (const val in filterValues) {
            if (!first) {
              valueStr = valueStr + " OR ";
            }
            valueStr = valueStr + "\"" + filterValues[val] + "\"";
            first = false;
          }

          const completeFilterValueStr = "{!tag=" + key + "}" + key + ":(" + valueStr + ")";
          params.append("fq", completeFilterValueStr);
        }
      }
    
      const urlStr = configData.search[0].search_host;
      const axiosParam = {
        url: urlStr,
        method: 'get',
        params: params,
        auth: {
          username: configData.search[0].search_user,
          password: configData.search[0].search_pswd
        }
      };
      const response = await axios.request(axiosParam);

      // const docs_urlStr = configData.documentation[0].search_host;
      // params.append("rows", "3");
      // const docs_axiosParam = {
      //   url: docs_urlStr,
      //   method: 'get',
      //   params: params,
      //   auth: {
      //     username: configData.documentation[0].search_user,
      //     password: configData.documentation[0].search_pswd
      //   }
      // };
      // const docs_response = await axios.request(docs_axiosParam);
      // response.data.documentation = docs_response.data;

      return response.data;
    } else {
      console.debug("Search not performed due to missing required parameters");
    }
  }
);

export const SearchSlice = createSlice({
  name: 'search',
  initialState: {
    status: {pending: "false"},
    response: "placeholder",
    docs: {},
    processing: false,
    request: {
      params: {
        session_id: uuidv4()
      },
      filters: {

      },
      auth: {
        authenticated: false,
        username:  configData.search_user,
        password:  configData.search_pswd
      }
    }
  },
  reducers: {
    setRequestParam: (state, action) => {
      const paramName = action.payload.paramName;
      const paramValue = action.payload.paramValue;
      state.request.params[paramName] = paramValue;
    },
    removeRequestParam: (state, action) => {
      const paramName = action.payload.paramName;
      delete state.request.params[paramName] ;
    },
    clearAllParams: (state, action) => {
      state.request.params = {} ;
    },
    setFilter: (state, action) => {
      const filterName = action.payload.filterName;
      const filterValue = action.payload.filterValue;
      state.request.filters[filterName] = filterValue;
    },
    removeFilter: (state, action) => {
      const filterName = action.payload.filterName;
      delete state.request.filters[filterName] ;
    },
    clearAllFilters: (state, action) => {
      state.request.filters = {} ;
    },
    clearResponse: (state, action) =>{
      state.response = "placeholder"
    }
  },
  extraReducers: (builder) => {
    builder.addCase(searchFusion.pending, (state, action) => {
      state.status.errorMsg = '';
      state.status.error = false;
      state.status.pending = true;
      state.status.fulfilled = false;
    })
    builder.addCase(searchFusion.fulfilled, (state, { payload }) => {
      state.response = payload
      state.status.pending = false;
      state.status.fulfilled = true;
    })
    builder.addCase(searchFusion.rejected, (state, action) => {
      if (action.payload) {
        state.status.errorMsg = action.payload.errorMessage
        state.status.error = true;
        state.status.fulfilled = false;
      } else {
        state.status.errorMsg = action.error.message
        state.status.error = true;
      }
    })
  },
});

export const {setRequestParam, removeRequestParam, setFilter, removeFilter, clearAllFilters, clearAllParams, clearResponse } = SearchSlice.actions;

export default SearchSlice.reducer;

export const getFusionResponse = state => {
  if(state  && state.search  && state.search.response){
    return state.search.response
  }
}

export const getFusionRequestParams = state => {
  if(state  && state.search  && state.search.request ){
    return state.search.request.params
  }
}

export const getFusionFilters = state => {
  if(state  && state.search  && state.search.request ){
    return state.search.request.filters
  }
}

export const getStatus = state => {
  if(state  && state.search  && state.search.status){
    return state.search.status
  }
}