import React, { useState } from 'react';
import { useDeleteQuestionMutation, useEditQuestionMutation } from '../Utility/RFP_API'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';


function DeletePrompt(props) {
  const deletePromptQuestion = props.deletePromptQuestion;
  const setDeletePromptQuestion = props.setDeletePromptQuestion;
  const question = deletePromptQuestion != null ? deletePromptQuestion.question.question : ""
  const [deleteQuestion] = useDeleteQuestionMutation();
  const [deleteError, setDeleteError] = useState("");

  const handleCancel = (event) => {
    event.preventDefault();
    setDeletePromptQuestion(null);
  }

  const handleDelete = (event) => {
    event.preventDefault();
    deleteQuestion({ rfp_id: deletePromptQuestion.rfp_id, question_id: deletePromptQuestion.question.id })
      .unwrap()
      .then((payload) => {
        setDeletePromptQuestion(null);
      }
      )
      .catch((error) => {
        setDeleteError("Status: " + error.status + ". " + error.data);
      }
      )
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="p-5 text-center">
          <h4>Are you sure you want to delete the question:</h4>
          <p>{question}</p>
          {(deleteError !== "")
            ?
            <div className="alert alert-danger" role="alert">
              An error occurred: {deleteError}
            </div>
            : <></>
          }
          <div className="py-3">
            <div className="btn-group" role="group" aria-label="Basic example">
              <button type="button" className="btn btn-primary mr-2" onClick={handleDelete}>Delete</button>
              <button type="button" className="btn btn-primary mr-2" onClick={handleCancel}>Cancel</button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export function ListQuestions(props) {
  const questionsCopy = JSON.parse(JSON.stringify(props.questions));
  const rfp_id = props.rfp_id;
  const [editQuestion] = useEditQuestionMutation();
  const [deletePromptQuestion, setDeletePromptQuestion] = useState(null);

  const columns = [
    {
      dataField: 'sequence',
      headerStyle: () => {
        return { width: "5%" };
      },
      text: 'Seq #',
      sort: true,
      headerClasses: 'sequence-column',
      align: (cell, row, rowIndex, colIndex) => {
        return 'center';
      }
    },
    {
      dataField: 'category',
      text: 'Category',
      headerStyle: () => {
        return { width: "10%" };
      },
      sort: true,
      headerClasses: 'category-column'
    },
    {
      dataField: 'question',
      text: 'Question',
      editor: { type: Type.TEXTAREA },
      sort: true,
      headerClasses: 'question-column'
    },
    {
      dataField: 'answer',
      text: 'Answer',
      editor: { type: Type.TEXTAREA },
      headerClasses: 'answer-column'
    },
    {
      dataField: 'notes',
      text: 'Notes',
      sort: true,
      headerStyle: () => {
        return { width: "20%" };
      }, editor: { type: Type.TEXTAREA },
      headerClasses: 'notes-column'
    },
    {
      dataField: "view",
      text: "View",
      editable: false,
      headerStyle: () => {
        return { width: "5%" };
      },
      headerClasses: 'edit-column',
      align: (cell, row, rowIndex, colIndex) => {
        return 'center';
      },
      headerAlign: (cell, row, rowIndex, colIndex) => {
        return 'center';
      },
      csvExport: false,
      formatter: (cellContent, row) => {
        return (
          <Link to={row.id} className="link_normal_color">
            <span><i className="fa fa-link"></i></span>
          </Link>
        );
      }
    },
    {
      dataField: "action",
      text: "Delete",
      editable: false,
      headerStyle: () => {
        return { width: "5%" };
      },
      headerClasses: 'edit-column',
      csvExport: false,
      align: (cell, row, rowIndex, colIndex) => {
        return 'center';
      },
      headerAlign: (cell, row, rowIndex, colIndex) => {
        return 'center';
      },
      formatter: (cellContent, row) => {
        return (
            <a href="/" onClick={handleDelete(rfp_id, row)} className="link_normal_color">
              <span><i className="fa fa-trash"></i></span> 
            </a>
        );
      }
    },
  ];



  const handleDelete = (rfp_id, question) => (event) => {
    event.preventDefault();
    setDeletePromptQuestion({ rfp_id: rfp_id, question: question });
    //deleteQuestion({ rfp_id, question_id });
  }



  const { ExportCSVButton } = CSVExport;


  const cellEdit = cellEditFactory({
    mode: 'dbclick',
    blurToSave: true,
    afterSaveCell: (oldValue, newValue, row, column) => {
      if (oldValue !== newValue) {
        if (column.dataField === 'sequence') {
          const newSequenceValue = parseInt(row.sequence);
          delete row.sequence;
          row.sequence = newSequenceValue;
        }
        console.log(`after save cell ${oldValue} , ${newValue} ${JSON.stringify(row)} , ${column.dataField}`);
        editQuestion({ "rfp_id": rfp_id, "question_id": row.id, "body": row });
      }
    }
  })


  return (
    <>
      <DeletePrompt show={deletePromptQuestion != null} deletePromptQuestion={deletePromptQuestion} setDeletePromptQuestion={setDeletePromptQuestion} />
      <div>
        Help / Tips:
        <ul>
          <li>Double click cells to edit</li>
          <li>Click outside of the edited cell to save</li>
          <li>Click on any question's View <span> <i className="fa fa-link"></i> </span> link to see Smart Answer suggestions</li>
        </ul>
      </div>
      <div>
        <ToolkitProvider
          keyField="id"
          data={questionsCopy}
          columns={columns}

          exportCSV={{
            fileName: 'custom.csv',
            separator: ',',
            ignoreHeader: true,
            noAutoBOM: false
          }}
        >
          {
            props =>
              <div>
                <BootstrapTable {...props.baseProps}
                  bootstrap4
                  cellEdit={cellEdit}
                  striped
                  hover
                  condensed
                  tabIndexCell
                />
                <ExportCSVButton className="btn btn-secondary" {...props.csvProps}>Export CSV</ExportCSVButton>
              </div>
          }
        </ToolkitProvider>
      </div>
    </>

  );
}
