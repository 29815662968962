import { configureStore } from '@reduxjs/toolkit';
import searchReducer from './_components/Utility/SearchSlice';
import userReducer from './_components/Utility/UserSlice';
import {rfpAPI} from './_components/Utility/RFP_API'
import {searchAPI} from './_components/Utility/Search_API'
import { setupListeners } from '@reduxjs/toolkit/query'

export const store = configureStore({
  reducer: {
    search: searchReducer,
    user: userReducer,
    [rfpAPI.reducerPath]: rfpAPI.reducer,
    [searchAPI.reducerPath]: searchAPI.reducer
  },

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(rfpAPI.middleware)
      .concat(searchAPI.middleware),
});


// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)
