import React from "react";

function Bookmark() {
  return (
    <button
      className="btn btn-sm"
      type="button"
      title="Copy Answer"
      href="/#"
    >
      <i className="fas fa-star"></i>
    </button>
  );
}

export default Bookmark;
