import React from "react";
import { Outlet } from "react-router-dom";
import { useGetRFPSQuery } from "../Utility/RFP_API";
import { Spinner, Button } from "react-bootstrap";
import SearchIndexManagement from "./SearchIndexManagement";

export function PageAdminRFP(props) {
  return (
    <>
      <div id="content">
        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
          <button
            id="sidebarToggleTop"
            className="btn btn-link d-md-none rounded-circle mr-3"
          >
            <i className="fa fa-bars"></i>
          </button>
          <div className="content-fluid">
          <h1 className="h3 mb-2 text-gray-800">RFP Index Administration</h1>
          </div>
         

          <ul className="navbar-nav ml-auto">
            <div className="topbar-divider d-none d-sm-block"></div>
            <li className="nav-item dropdown no-arrow">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                id="userDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                  {props.userInfo.givenName} {props.userInfo.familyName}
                </span>
                <img
                  className="img-profile rounded-circle"
                  referrerPolicy="no-referrer"
                  src={props.userInfo.imageURL}
                />
              </a>

              <div
                className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                aria-labelledby="userDropdown"
              >
                <a
                  className="dropdown-item"
                  href="/#"
                  data-toggle="modal"
                  data-target="#logoutModal"
                >
                  <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                  Logout
                </a>
              </div>
            </li>
          </ul>
        </nav>
        <div className="container-fluid">
          {" "}
          <RFPList />
        </div>
      </div>
    </>
  );
}

export function RFPList(props) {
  const { data, error, isLoading } = useGetRFPSQuery();

  return (
    <>
      <div>
        {error ? (
          <>
            <div className="alert alert-danger" role="alert">
              An error occurred: {error.error}
            </div>
          </>
        ) : isLoading ? (
          <Button variant="primary" disabled>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Loading...
          </Button>
        ) : data ? (
          <>
            <SearchIndexManagement questions={data} />
          </>
        ) : null}
      </div>
      <Outlet />
    </>
  );
}
