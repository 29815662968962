import React, { useState } from "react";
import ResponseResults from "./_components/Results/ResponseResults";
import {
  setUserInfoInSlice,
  setIdToken,
} from "./_components/Utility/UserSlice";
import { useDispatch } from "react-redux";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import QuestionTextBox from "./_components/UI/QuestionTextBox";
import "./App.css";
import { PageListRFPs } from "./_components/RFP/PageListRFPs";
import { PageAddRFP } from "./_components/RFP/PageAddRFP";
import { PageAdminRFP } from "./_components/RFP/PageAdminRFP";
import { RFP } from "./_components/RFP/RFP";
import { Question } from "./_components/RFP/Question";
import { Documentation } from "./_components/Results/Documentation";
import { Routes, Link, Route, NavLink } from "react-router-dom";

const CLIENT_ID =
  "222572739365-e1h37v87t8f7o0m9qg6705b7tsr91ks3.apps.googleusercontent.com";

export default function App() {
  const [userInfo, setUserInfo] = useState({
    name: "",
    emailId: "",
    isLoggedIn: "false",
    familyName: "",
    givenName: "",
    imageURL: "",
  });
  const dispatch = useDispatch();

  // Success Handler
  const responseGoogleSuccess = (response) => {
    // console.log(response);
    let userInfo = {
      name: response.profileObj.name,
      emailId: response.profileObj.email,
      isLoggedIn: "true",
      familyName: response.profileObj.familyName,
      givenName: response.profileObj.givenName,
      imageURL: response.profileObj.imageUrl,
    };
    setUserInfo(userInfo);
    dispatch(setUserInfoInSlice(userInfo));
    dispatch(setIdToken(response.tokenId));
  };

  // Error Handler
  const responseGoogleError = (response) => {
    console.log(response);
  };

  // Logout Session and Update State
  const logout = (response) => {
    console.log(response);
    let userInfo = {
      name: "",
      emailId: "",
      isLoggedIn: "false",
      familyName: "",
      givenName: "",
      imageURL: "",
    };
    setUserInfo(userInfo);
  };

  return (
    <div id="wrapper">
      <ul
        className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        id="accordionSidebar"
      >
        <Link
          className="sidebar-brand d-flex align-items-center justify-content-center"
          to="/"
        >
          <div className="sidebar-brand-icon">
            <img
              src="/img/LW_logomark_blue-01.jpg"
              alt="Lucidworks Logo"
              height="50"
            />
          </div>
          <div className="sidebar-brand-text mx-3">RFP Tool</div>
        </Link>

        <hr className="sidebar-divider my-0" />

        <hr className="sidebar-divider" />

        <div className="sidebar-heading">Pages</div>

        <li className="nav-item p-0">
          <Link className="nav-link" to="/">
            <i className="fa-solid fa-magnifying-glass"></i>
            <span>Search</span>
          </Link>
        </li>


        <hr className="sidebar-divider" />

        <div className="sidebar-heading">Project</div>

        <li className="nav-item">
          <a
            className="nav-link pt-2 pb-0"
            href="https://demo-engineering.lucidworkssales.com/auth/"
            target="_blank"
          >
            <i className="fa-solid fa-server"></i>
            <span>Fusion</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link pt-2 pb-0"
            href="https://github.com/bkisselbach/react_sandbox"
            target="_blank"
          >
            <i className="fa-brands fa-github"></i>
            <span>Git Repo</span>
          </a>
        </li>

        <li className="nav-item">
          <a
            className="nav-link pt-2"
            href="https://lucidworks.atlassian.net/jira/software/c/projects/RT/boards/472"
            target="_blank"
          >
            <i className="fa-brands fa-jira"></i>
            <span>Jira</span>
          </a>
        </li>

        <hr className="sidebar-divider d-none d-md-block" />
      </ul>

      <div id="content-wrapper" className="d-flex flex-column">
        {userInfo.isLoggedIn === "true" ? (
          <>
            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route path="/rfp_list">
                <Route index element={<PageListRFPs userInfo={userInfo} />} />
                <Route path=":rfp_id">
                  <Route index element={<RFP />} />
                  <Route path=":question_id" element={<Question />} />
                </Route>
              </Route>
              <Route
                path="/rfp_add"
                element={<PageAddRFP userInfo={userInfo} />}
              ></Route>
              <Route
                path="/rfp_admin"
                element={<PageAdminRFP userInfo={userInfo} />}
              ></Route>
              <Route
                path="/documentation"
                element={<Documentation userInfo={userInfo} />}
              ></Route>
              <Route path="*" element={<h1>Not Found</h1>} />
            </Routes>
          </>
        ) : (
          <>
            <RFPGoogleLogin
              responseGoogleSuccess={responseGoogleSuccess}
              responseGoogleError={responseGoogleError}
            />
          </>
        )}

        {/* <footer className="sticky-footer bg-white">
            <div className="container my-auto">
                <div className="copyright text-center my-auto">
                    <span>Copyright &copy; Your Website 2021</span>
                </div>
            </div>
        </footer>  */}
      </div>
    </div>
  );
}

function Home() {
  const [userInfo, setUserInfo] = useState({
    name: "",
    emailId: "",
    isLoggedIn: "false",
    familyName: "",
    givenName: "",
    imageURL: "",
  });
  const dispatch = useDispatch();

  // Success Handler
  const responseGoogleSuccess = (response) => {
    // console.log(response);
    let userInfo = {
      name: response.profileObj.name,
      emailId: response.profileObj.email,
      isLoggedIn: "true",
      familyName: response.profileObj.familyName,
      givenName: response.profileObj.givenName,
      imageURL: response.profileObj.imageUrl,
    };
    setUserInfo(userInfo);
    dispatch(setUserInfoInSlice(userInfo));
    dispatch(setIdToken(response.tokenId));
  };

  // Error Handler
  const responseGoogleError = (response) => {
    console.log(response);
  };

  return (
    <div id="content">
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        <button
          id="sidebarToggleTop"
          className="btn btn-link d-md-none rounded-circle mr-3"
        >
          <i className="fa fa-bars"></i>
        </button>

        <QuestionTextBox emailId={userInfo.emailId} />

        <ul className="navbar-nav ml-auto">
          <div className="topbar-divider d-none d-sm-block"></div>

          {userInfo.isLoggedIn === "true" ? (
            <li className="nav-item dropdown no-arrow">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                id="userDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                  {userInfo.givenName} {userInfo.familyName}
                </span>
                <img
                  className="img-profile rounded-circle"
                  referrerPolicy="no-referrer"
                  src={userInfo.imageURL}
                />
              </a>

              <div
                className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                aria-labelledby="userDropdown"
              >
                {/* <div className="dropdown-divider"></div> */}
                <a
                  className="dropdown-item"
                  href="/#"
                  data-toggle="modal"
                  data-target="#logoutModal"
                >
                  <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                  Logout
                </a>
              </div>
            </li>
          ) : (
            <>
              <div className="container">
                <div className="row justify-content-center pt-5 my-5">
                  <div className="col-xl-10 col-lg-12 col-md-9">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                      <div className="card-body p-0">
                        <div className="row">
                          <div className="col-lg-6 d-none d-lg-block bg-password-image"></div>
                          <div className="col-lg-6">
                            <div className="p-5">
                              <div className="text-center">
                                <h1 className="h4 text-gray-900 mb-2">
                                  Welcome
                                </h1>
                                <p className="mb-4">
                                  Please login with your Lucidworks Account
                                </p>
                              </div>
                              <form className="user text-center">
                                <GoogleLogin
                                  clientId={CLIENT_ID}
                                  buttonText="Sign In with Google"
                                  onSuccess={responseGoogleSuccess}
                                  onFailure={responseGoogleError}
                                  isSignedIn={true}
                                  cookiePolicy={"single_host_origin"}
                                />
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </ul>
      </nav>
      <ResponseResults />
    </div>
  );
}

function RFPGoogleLogin(props) {
  const responseGoogleSuccess = props.responseGoogleSuccess;
  const responseGoogleError = props.responseGoogleError;

  return (
    <div className="container">
      <div className="row justify-content-center pt-5 my-5">
        <div className="col-xl-10 col-lg-12 col-md-9">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-lg-6 d-none d-lg-block bg-password-image"></div>
                <div className="col-lg-6">
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-2">Welcome</h1>
                      <p className="mb-4">
                        Please login with your Lucidworks Account
                      </p>
                    </div>
                    <form className="user text-center">
                      <GoogleLogin
                        clientId={CLIENT_ID}
                        buttonText="Sign In with Google"
                        onSuccess={responseGoogleSuccess}
                        onFailure={responseGoogleError}
                        isSignedIn={true}
                        cookiePolicy={"single_host_origin"}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
