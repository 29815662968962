import React from 'react';
import { useAddQuestionMutation } from '../Utility/RFP_API'

export default function NewQuestion(props) {
  const setTabKey = props.setTabKey;
  const [question, setQuestion] = React.useState('');
  const [answer, setAnswer] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [notes, setNotes] = React.useState('');

  const next_sequence = props.next_sequence;
  const rfp_id = props.rfp_id;
  const [addNewPost] = useAddQuestionMutation()

  const addQuestion = (rfp_id, body) => (event) => {
    event.preventDefault();
    addNewPost({ rfp_id, body });

    //clear the form
    setQuestion("")
    setAnswer("")
    setCategory("")
    setNotes("")

    //return to Questions Tab
    setTabKey("questions");
  }

  return (
    <>
          <div className=".container-md" id="add-question">
      <h3>Add a new Question</h3>
      <form onSubmit={addQuestion(rfp_id,
        {
          sequence: parseInt(next_sequence),
          answer: answer,
          question: question,
          category: category,
          notes: notes,
        })}>
        <div className="form-group row">
          <label className="col-sm-1 col-form-label">Category</label>
          <div className="col-sm-6">
            <input type="text" className="form-control" value={category} onChange={(e) => setCategory(e.target.value)} />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-1 col-form-label">Question</label>
          <div className="col-sm-6">
            <textarea className="form-control" rows="2" value={question} onChange={(e) => setQuestion(e.target.value)} required></textarea>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-1 col-form-label">Answer</label>
          <div className="col-sm-6">
            <textarea className="form-control" rows="3" value={answer} onChange={(e) => setAnswer(e.target.value)}></textarea>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-1 col-form-label">Notes</label>
          <div className="col-sm-6">
            <textarea className="form-control" rows="3" value={notes} onChange={(e) => setNotes(e.target.value)}></textarea>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-sm-10">
            <button type="submit" className="btn btn-primary"> Add </button>
          </div>
        </div>
      </form>
      </div>
    </>
  );
}
