import React from "react";
import configData from "../../system_config.json";
import { getUserInfo } from "./UserSlice";
import { useSelector } from "react-redux";
import { getFusionRequestParams } from "./SearchSlice";

export default function SetAnswer(props) {
  const answer = props.answer
  const setAnswer = props.setAnswer

  const handleClick = (event) => {
    event.preventDefault()
    console.log("Answer selected " + answer)
    setAnswer(answer)
  };


  return (
    <button
    className="btn btn-secondary btn-sm"
      type="button"
      onClick={handleClick}
      title="Paste to Answer"
      href="/#"
    >
            <span >
            <i className="fa fa-paste" aria-hidden="true"></i>
      </span>

    </button>
  );
}

