import React, { useState, useEffect } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import { useSearchableQuestionMutation } from '../Utility/RFP_API'
import { useIndexQuestionsMutation, useRfp_indexed_questionsQuery, useDeleteQuestionsMutation } from '../Utility/Search_API';
import { Spinner, Modal} from 'react-bootstrap';

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >

      <Modal.Body>
        <div className="p-5 text-center">
          Loading <Spinner
                as="span"
                animation="grow"
                size="xl"
                role="status"
                aria-hidden="true"
              />
        </div>
        <div></div>
      </Modal.Body>
    </Modal>
  );
}

export default function QuestionsManageSearch(props) {
  const rfp_id = props.rfp_id;
  const { data, error, isLoading , refetch} = useRfp_indexed_questionsQuery(rfp_id);
  return (
    <>
     <MyVerticallyCenteredModal key="useRfp_indexed_questionsQuery" show={isLoading}/>
      <div>
        {error ? (
        <>
        <div className="alert alert-danger" role="alert">
            An error occurred: {error.error}
        </div>
        </>
        ) : isLoading ? (
          <>Fusion State is loading ...</>
        ) : data ? (
          <>
            <MergeFusionAndRFPDatabaseResults questions={props.questions} rfp_id={rfp_id} fusionData={data} refetch={refetch} />
          </>
        ) : null}
      </div>
    </>
  );
}

export function MergeFusionAndRFPDatabaseResults(props) {
  const fusionData = props.fusionData;
  const questions = props.questions;
  const rfp_id = props.rfp_id;
  const refetch= props.refetch;

  const fusionIDs = (fusionData != null && fusionData['response'] !== null && fusionData['response']['docs'] !== null) ? fusionData['response']['docs'] : [];
  const indexedIDs = fusionIDs.reduce((a, v) => ({ ...a, [v['id']]: 1 }), {});
  const mergedRFPDatabaseAndFusion = questions.map((value) => {
    const question = { ...value }
    question['indexed'] = (indexedIDs[question.id] === 1) ? true : false
    return question;
  })

  return (
    <>
      <CombinedDatabaseAndFusionView questions={mergedRFPDatabaseAndFusion} rfp_id={rfp_id} refetch={refetch}/>
    </>
  )
}


export function CombinedDatabaseAndFusionView(props) {
  const questionsCopy = JSON.parse(JSON.stringify(props.questions));
  const rfp_id = props.rfp_id;
  const [selected, setSelected] = useState({});
  const [selectedEmpty, setSelectedEmpty] = useState(true);
  const [makeSearchableTrigger,  { isLoading: makeSearchableIsLoading }] = useSearchableQuestionMutation();
  const [indexQuestionTrigger, {isLoading: indexQuestionIsLoading}] = useIndexQuestionsMutation();
  const [deleteQuestionTrigger] = useDeleteQuestionsMutation();
  const refetch = props.refetch;

  useEffect(() => {
    if (Object.keys(selected).length > 0) {
      setSelectedEmpty(false);
    }
    else {
      setSelectedEmpty(true);
    }
  }, [selected]);

  const columns = [
    {
      dataField: 'question',
      text: 'Question',
      headerStyle: () => {
        return { width: "20%" };
      },
      sort: true,
    },
    {
      dataField: 'answer',
      text: 'Answer',
      headerStyle: () => {
        return { width: "60%" };
      }
    }
    ,
    {
      dataField: 'searchable',
      text: 'Searchable',
      sort: true,
      align: (cell, row, rowIndex, colIndex) => {
        return 'center';
      },
      headerAlign: (cell, row, rowIndex, colIndex) => {
        return 'center';
      },
      headerStyle: () => {
        return { width: "10%" };
      },
      formatter: (cellContent, row) => {
        var searchable = false;
        if (cellContent != null) {
          searchable = Boolean(cellContent);
        }
        return (
          <>
            {(searchable)
              ? <span><i className="fa fa-check" ></i></span> 
              : <span><i className="fa fa-ban"></i></span>
            }
          </>
        );
      }
    }
    ,
    {
      dataField: 'indexed',
      text: 'Index Status',
      sort: true,
      align: (cell, row, rowIndex, colIndex) => {
        return 'center';
      },
      headerAlign: (cell, row, rowIndex, colIndex) => {
        return 'center';
      },
      headerStyle: () => {
        return { width: "10%" };
      },
      formatter: (cellContent, row) => {
        var indexed = false;
        if (cellContent != null) {
          indexed = Boolean(cellContent);
        }
        return (
          <>
            {(indexed)
              ? <span><i className="fa fa-check" ></i></span> 
              : <span><i className="fa fa-ban"></i></span>
            }
          </>
        );
      }
    }
  ];

  const handleOnSelect = (row, isSelect) => {

    if (isSelect) {
      const newSelection = { ...selected, [row.id]: 1 };
      setSelected(newSelection);
    } else {
      const newSelection = { ...selected };
      delete newSelection[row.id];
      setSelected(newSelection);
    }
  }

  const handleOnSelectAll = (isSelect, rows) => {
    if (isSelect) {
      const ids = rows.map(r => r.id);
      //convert ids which is an array of strings to Object/Map where the id is the key, and value is 1
      const newSelection = ids.reduce((a, v) => ({ ...a, [v]: 1 }), {})
      setSelected(newSelection);
    } else {
      setSelected({});
    }
  }

  const handleMakeSearchable = (searchable) => (event) => {
    const question_ids = Object.keys(selected);
    makeSearchableTrigger({
      rfp_id: rfp_id,
      searchable: searchable,
      body: question_ids
    }
    )
  }

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll
  };



  const handleIndexToFusion = (event) => {
    const indexPayload = questionsCopy
      .filter((question) => question['searchable'] === true)
      .map((question) => {
        const newQuestion = {
          question_t: question['question'],
          answer_t: question['answer'],
          category_s: question['category'],
          id: question['id'],
          rfp_id_s: rfp_id
        }
        return newQuestion;
      })

    if (indexPayload.length > 0) {
      indexQuestionTrigger({ body: indexPayload });
    }

    //check for questions that are not supposed to be indexed, but status says it's indexed
    const deletePayload = questionsCopy
      .filter((question) => question['searchable'] === false && question['indexed'] === true)
      .map((question) => { return { id: question.id } })

    deleteQuestionTrigger({ body: deletePayload });
  }



  return (
    <>
     <MyVerticallyCenteredModal key="makeSearchableIsLoading" show={makeSearchableIsLoading}/>
     <MyVerticallyCenteredModal key="indexQuestionIsLoading" show={indexQuestionIsLoading}/>

     <div className="py-3">
     <div>
      Help / Tips:
      <ul>
        <li>Select All or Individual Items (see checkbox on the left) that you want to either Mark as Searchable or Not Searchable</li>
        <li>Mark items Searchable <span><i className="fa fa-check" ></i></span>  or Not Searchable  <span><i className="fa fa-ban" ></i></span>   by clicking on the Buttons 'Mark Searchable' or "Mark Not Searchable'</li>
        <li>Clicking on the button - 'Index Marked Items To Fusion' indexes the items that you marked</li>
        <li>After Indexing Items to Fusion, there may be a delay in updating the Index Status column and you may need to use "Refresh Index Status" Button after watiting at least 15 seconds</li>
        <li>Items that are marked Not Searchable or <span><i className="fa fa-ban" ></i></span>  but have an Index Status of <span><i className="fa fa-check" ></i></span>  can be removed from the Index by also clicking on the 'Index Marked Items To Fusion'</li>
      </ul>
     </div>
     </div>
      <div className="py-3">
        <div className="btn-group" role="group" aria-label="Basic example">
          <button type="button" className="btn btn-primary mr-2" disabled={(selectedEmpty || makeSearchableIsLoading)} onClick={handleMakeSearchable(true)}>Mark Searchable</button>
          <button type="button" className="btn btn-primary mr-2" disabled={selectedEmpty || makeSearchableIsLoading} onClick={handleMakeSearchable(false)}>Mark Not Searchable</button>
          <button type="button" className="btn btn-primary mr-2" onClick={handleIndexToFusion} >Index Marked Items To Fusion</button>
          <button type="button" className="btn btn-primary" onClick={refetch} >Refresh Index Status</button>

        </div>
      </div>
      <div>
        <BootstrapTable
          keyField='id'
          data={questionsCopy}
          columns={columns}
          selectRow={selectRow}
          striped
          hover
          condensed
          tabIndexCell
        />
      </div>
    </>

  );
}
